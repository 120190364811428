import React from "react";
import ResumeCard from "./ResumeCard";
import {
  TbCircleNumber1,
  TbCircleNumber2,
  TbCircleNumber3,
  TbCircleNumber4,
} from "react-icons/tb";
import formIMG from "../../assets/images/Forms-bro.png";
import recruitIMG from "../../assets/images/Profile data-cuate.png";
import recruitIMG2 from "../../assets/images/Interview-bro.png";
import recruitIMG3 from "../../assets/images/Multi-device targeting-amico.png";

const Resume = () => {
  return (
    <div className="px-4 sm:px-6 md:px-8">
      <div className="py-6 sm:py-8 md:py-12 font-titleFont">
        <p className="text-sm sm:text-base text-blue-300 font-bold tracking-[4px]">
          FLOW
        </p>
        <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-black mt-2">
          選考の流れ
        </h2>
      </div>

      <div className="w-full lg:w-[60%] h-full border-l-[2px] sm:border-l-4 border-black flex flex-col gap-6 sm:gap-8 md:gap-10">
        <ResumeCard
          icon={<TbCircleNumber1 />}
          subtitle="お問合せフォームよりエントリー"
          des={
            <ul className="list-inside space-y-2 list-none">
              <li>採用情報をご確認のうえ、ご応募ください。</li>
              <li>ぜひ気軽にエントリーしてください！</li>
            </ul>
          }
          img={formIMG}
        />

        <ResumeCard
          icon={<TbCircleNumber2 />}
          subtitle="書類選考"
          des="採用担当が応募書類確認後、選考結果をご連絡いたします。"
          img={recruitIMG}
        />

        <ResumeCard
          icon={<TbCircleNumber3 />}
          subtitle="面接"
          des={
            <ul className="list-inside space-y-2 list-none">
              <li>まずは弊社のことを知ってください！</li>
              <li>あなたのこともざっくばらんにお話を聞かせてください。</li>
              <li>お気軽に希望や要望もご相談ください！</li>
            </ul>
          }
          img={recruitIMG2}
        />

      </div>
    </div>
  );
};

export default Resume;
