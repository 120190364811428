import React from "react";
import ukeoiImg from "../../assets/images/kaihatu.png";
import inhuraImg from "../../assets/images/inhura.png";
import {
  FaAmazon,
  FaJava,
  FaPython,
  FaSwift,
  FaHtml5,
  FaLinux,
  FaWindows,
  FaDocker,
  FaReact,
} from "react-icons/fa";
import { IoLogoJavascript } from "react-icons/io";
import {
  SiKotlin,
  SiFlutter,
  SiMicrosoftazure,
  SiKubernetes,
  SiAnsible,
  SiTerraform,
} from "react-icons/si";
import { BiLogoGoogleCloud } from "react-icons/bi";
import LeftBunner from "../Banner/LeftBanner";

const Skill = () => {
  return (
    <div className="my-4 sm:my-8 md:my-20 font-titleFont flex flex-col items-center">
      <div className="bg-gray-100 w-full h-auto p-3 sm:p-4 md:p-7 lg:w-4/5 lg:mx-auto">
        <div className="font-titleFont mb-6 sm:mb-12 md:mb-20 relative text-right">
          <h3 className="text-3xl sm:text-5xl md:text-7xl lg:text-6xl font-extrabold text-blue-200 relative z-10">
            SKILL AREA
          </h3>
          <h1 className="text-xl sm:text-3xl md:text-5xl lg:text-4xl font-bold text-black absolute top-6 sm:top-12 md:top-12 lg:top-10 right-0 z-20">
            開発領域
          </h1>
        </div>
        <div className="text-lg sm:text-2xl md:text-3xl lg:text-2xl font-bold text-white my-2 sm:my-4 md:my-5 text-right bg-blue-500 py-3 sm:py-6 md:py-8 lg:py-6 rounded-lg">
          <h1 className="pr-2 sm:pr-4 md:pr-9">
            最新のトレンドに合った技術を提供
          </h1>
        </div>
        <div className="flex flex-col md:flex-row justify-between text-black gap-4 sm:gap-8 md:gap-12 my-6 sm:my-12 md:my-20">
          <SkillSection title="システム開発" skills={systemDevelopmentSkills} />
          <SkillSection1 title="インフラ構築" skills={infrastructureSkills} />
        </div>
      </div>
      <div className="bg-gray-300 w-full h-auto p-3 sm:p-4 md:p-7 lg:w-4/5 lg:mx-auto">
        <LeftBunner />
      </div>
    </div>
  );
};

const SkillSection = ({ title, skills }) => (
  <div className="w-full md:w-1/2 mb-4 sm:mb-8 md:mb-0">
    <div className="bg-white w-full h-auto p-3 sm:p-4 md:p-7 shadow-2xl rounded-lg">
      <img
        className="rounded-lg w-full h-24 sm:h-32 md:h-40 lg:h-36 object-cover"
        src={ukeoiImg}
        alt={`${title}Img`}
      />
      <h2 className="text-xl sm:text-3xl md:text-3xl lg:text-2xl font-bold text-black mb-2 sm:mb-4 py-3 sm:py-5 md:py-7 lg:py-5">
        {title}
      </h2>
      {skills.map((skillGroup, index) => (
        <div key={index}>
          <h1 className="text-lg sm:text-2xl md:text-2xl lg:text-xl font-bold mb-2 sm:mb-4 md:mb-5 text-blue-400 mt-6">
            {skillGroup.title}
          </h1>
          <div className="pl-3 sm:pl-6 md:pl-8 lg:pl-6">
            <table className="text-base sm:text-xl md:text-2xl lg:text-xl w-full">
              <tbody className="flex flex-col gap-2 sm:gap-3 md:gap-4">
                {skillGroup.items.map((item, i) => (
                  <tr key={i} className="flex items-center">
                    <th className="bg-gray-300 p-1 sm:p-1 md:p-2 rounded-2xl">
                      {item.icon}
                    </th>
                    <td className="px-2 sm:px-3 md:px-4">{item.name}</td>
                    {item.details && (
                      <td className="text-sm sm:text-lg md:text-xl lg:text-lg">
                        {item.details}
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ))}
    </div>
  </div>
);

const SkillSection1 = ({ title, skills }) => (
  <div className="w-full md:w-1/2 mb-4 sm:mb-8 md:mb-0">
    <div className="bg-white w-full h-auto p-3 sm:p-4 md:p-7 shadow-2xl rounded-lg">
      <img
        className="rounded-lg w-full h-24 sm:h-32 md:h-40 lg:h-36 object-cover"
        src={inhuraImg}
        alt={`${title}Img`}
      />
      <h2 className="text-xl sm:text-3xl md:text-3xl lg:text-2xl font-bold text-black mb-2 sm:mb-4 py-3 sm:py-5 md:py-7 lg:py-5">
        {title}
      </h2>
      {skills.map((skillGroup, index) => (
        <div key={index}>
          <h1 className="text-lg sm:text-2xl md:text-2xl lg:text-xl font-bold mb-2 sm:mb-4 md:mb-5 text-blue-400 mt-6">
            {skillGroup.title}
          </h1>
          <div className="pl-3 sm:pl-6 md:pl-8 lg:pl-6">
            <table className="text-base sm:text-xl md:text-2xl lg:text-xl w-full">
              <tbody className="flex flex-col gap-2 sm:gap-3 md:gap-4">
                {skillGroup.items.map((item, i) => (
                  <tr key={i} className="flex items-center">
                    <th className="bg-gray-300 p-1 sm:p-1 md:p-2 rounded-2xl">
                      {item.icon}
                    </th>
                    <td className="px-2 sm:px-3 md:px-4">{item.name}</td>
                    {item.details && (
                      <td className="text-sm sm:text-lg md:text-xl lg:text-lg">
                        {item.details}
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ))}
    </div>
  </div>
);

const systemDevelopmentSkills = [
  {
    title: "システム開発",
    items: [
      { icon: <FaJava />, name: "Java", details: "(Spring/SpringBoot)" },
      { icon: <FaPython />, name: "Python", details: "(Django)" },
      {
        icon: <IoLogoJavascript />,
        name: "JavaScript",
        details: "(React/Vue/TS)",
      },
      { icon: "C", name: "C#" },
      { icon: <FaHtml5 />, name: "HTML/CSS" },
    ],
  },
  {
    title: "モバイルアプリ開発",
    items: [
      { icon: <FaSwift />, name: "Swift" },
      { icon: <SiKotlin />, name: "Kotlin" },
      { icon: <SiFlutter />, name: "Flutter" },
      { icon: <FaReact />, name: "ReactNative" },
    ],
  },
];

const infrastructureSkills = [
  {
    title: "クラウド",
    items: [
      { icon: <FaAmazon />, name: "AWS(Amazon Web Services)" },
      { icon: <SiMicrosoftazure />, name: "Azure" },
      { icon: <BiLogoGoogleCloud />, name: "GCP(Google Cloud Platform)" },
    ],
  },
  {
    title: "オンプレ",
    items: [
      { icon: <FaLinux />, name: "Linux" },
      { icon: <FaWindows />, name: "Windows" },
    ],
  },
  {
    title: "コンテナ技術",
    items: [
      { icon: <FaDocker />, name: "Docker" },
      { icon: <SiKubernetes />, name: "Kubernetes" },
    ],
  },
  {
    title: "構築自動化",
    items: [
      { icon: <SiAnsible />, name: "Ansible" },
      { icon: <SiTerraform />, name: "Terraform" },
    ],
  },
];

export default Skill;
