import "./App.css";
import Banner from "./Components/Banner/Banner";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Form from "./Components/Forfm/Form";
import Footer from "./Components/Footer/Footer";
import Features from "./Components/Features/Features";
import Business from "./Components/Business/Business";
import Navtest from "./Components/Navtest";
import Recruit from "./Components/Recruit/Recruit";

function App() {
  return (
    <div className="w-full h-auto  ">
      <div>
        <BrowserRouter>
          <Navtest />

          <Routes>
            <Route path="/" element={<Banner />} />
            <Route path="/form" element={<Form />} />
            <Route path="/features" element={<Features />} />
            <Route path="/company" element={<Business />} />
            <Route path="/recruit" element={<Recruit />} />
          </Routes>

          <Footer />
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
