import React from 'react'
import companyLogo from '../../assets/images/1.png'

const CompanyNameTest = () => {
  return (
    <div className='py-24 '> 
      <div className='flex flex-col md:flex-row items-center justify-center p-4 shadow-shadowOne '>
        <div className='w-full md:w-1/2 flex justify-center items-center mb-8 md:mb-0'>
          <img className='w-80 h-80 object-contain' src={companyLogo} alt="Company Logo" />
        </div>
        <div className='md:w-1/2 text-left md:text-left'>
          <p className='text-sm md:text-xl text-white mb-4'>
            個人主義が強くなっていく世の中で、社員皆が会社という船の乗組員になり、同じ方向に向かって一致団結していけるよう願った社名となります。
          </p>
          <p className='text-sm md:text-xl text-white mt-8'>
            最初は小舟でも皆で協力し巨大な船にしていき、新たな船(新規事業)を生み出し船団となっていくことを目指しています。巨大な船にしていくため、一緒に成長しアワーズシップが目指す未来に向けて協力してくれる仲間を求めています。
          </p>
        </div>
      </div>
    </div>
  )
}

export default CompanyNameTest
